var irTopo = (function (){
	$('#go-topo').click(function(e){
		e.preventDefault();
		$('html,body').animate({
			scrollTop:0
		}, 1000)
	});

	var jaVerificou = false;
	var verificar = function(){
		if($(window).scrollTop() >= (window.innerHeight/2)){
			$('#go-topo').addClass('active');
		}
		else{
			$('#go-topo').removeClass('active');
		}
	}

	verificar();

	$(window).scroll(function(){
		if(jaVerificou) return;

		setTimeout(function(){
			jaVerificou = false;
		},100);

		verificar();
	})
})();